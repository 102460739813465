<template>
  <section id="liste_actions">
    <p
      v-if="actions.length"
      class="text-small italic"
    >
      {{ message }}
    </p>
    <div class="actions centered">
      <ButtonClassic
        v-if="actions.length === 1"
        variant="special"
        :label="actions[0].label"
        color="primary"
        icon="left"
        :disabled="actions[0].disabled"
        @click="$emit('click-action', actions[0])"
      >
        <template #left-icon>
          <component :is="actions[0].icon" />
        </template>
      </ButtonClassic>
      <ButtonGroup v-else-if="actions.length > 1">
        <ButtonClassic
          v-for="action in actions"
          :key="action.slug"
          variant="solid"
          :label="action.label"
          color="white"
          icon="left"
          :disabled="action.disabled"
          @click="$emit('click-action', action)"
        >
          <template
            v-if="action.icon"
            #left-icon
          >
            <component :is="action.icon" />
          </template>
        </ButtonClassic>
      </ButtonGroup>
    </div>
    <div
      v-if="$route.name !== 'panier'"
      class="buttons-container"
      :class="{ centered: !isGE, }"
    >
      <ButtonClassic
        v-if="!isGE"
        v-tooltip="{
          content: $tc('general.mail-copie-info', 0, { mail: mailLde, } ),
          show: isDe,
          placement: 'top',
          delay: { show: 800, },
        }"
        variant="special"
        v-bind="btnAssistanceAttr"
        :label="$t('action.contactez-nous-pour-toute-question')"
        color="primary"
        icon="right"
        size="small"
        @click="copyMailLde()"
      >
        <template #right-icon>
          <UilComment />
        </template>
      </ButtonClassic>
      <div>
        <ButtonStatusApprobation
          v-tooltip="approbationTooltip"
          v-matomo-log-click="[`${context}_primary_btn_bas`, 'click', buttonLabel, ]"
          :variant="buttonStatus"
          :label="buttonLabel"
          large
          :disabled="buttonDisabled"
          @click="$emit('click-btn-status-approb', buttonStatus)"
        >
          <template #icon>
            <!-- @slot Emplacement pour une icône. -->
            <slot name="icon" />
          </template>
        </ButtonStatusApprobation>
        <!-- @slot Ajout d'un bouton additionnel -->
        <slot name="additional-buttons" />
      </div>
    </div>
  </section>
</template>

<script>
import config from "@/config";

import { ButtonClassic, ButtonGroup } from "@lde/core_lde_vue";

import ButtonStatusApprobation from "@/components/buttons/ButtonStatusApprobation.vue";

import { UilTrashAlt, UilComment } from "@iconscout/vue-unicons";

import { mapGetters } from "vuex";

/**
 * Affiche des boutons pour des actions possibles des listes de l'utilisateur (suppression, bouton
 * de contact, passage en devis).
 */
export default {
  name: "ListeActions",
  components: {
    ButtonClassic,
    UilTrashAlt,
    UilComment,
    ButtonGroup,
    ButtonStatusApprobation,
  },
  props: {
    /**
     * Liste des actions.
     */
    context: {
      type: String,
      validator: (value) => [
        "liste",
        "devis",
        "commande",
        "panier",
      ].includes(value),
      default: "liste",
    },
    /**
     * Liste des actions.
     */
    actions: {
      type: Array,
      default: () => [],
    },
    /**
     * Statut du bouton d'approbation.
     */
    buttonStatus: {
      type: String,
      validator: (value) => [
        "primary",
        "secondary",
        "warning",
        "positive",
        "white",
        "success",
        "error",
      ].includes(value),
      default: "primary",
    },
    /**
     * Label du bouton d'approbation.
     */
    buttonLabel: {
      type: String,
      default: "",
    },
    /**
     * Désactive le bouton d'approbation.
     */
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Tooltip sur le bouton d'approbation.
     */
    approbationTooltip: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: [
    /**
     * Déclenché au clic sur le bouton qui vide la liste.
     */
    "delete-list",
    /**
     * Déclenché au clic d'une action.
     * @param {Object} action Action sur laquel on a cliqué.
     */
    "click-action",
    /**
     * Déclenché au clic sur le bouton d'approbation.
     * @param {Object} buttonStatus Statut du bouton en bas à droite.
     */
    "click-btn-status-approb",
  ],
  computed: {
    config() {
      return config;
    },
    ...mapGetters(["isGE", "urlHotline", "country"]),
    message() {
      let message = "";

      if (this.$route.name === "listes_devis_liste_item") {
        message = this.$t("context-menu.actions-applicables-a-une-liste");
      } else if (this.$route.name === "listes_devis_devis_item") {
        message = this.$t("context-menu.actions-applicables-au-devis");
      } else if (this.$route.name === "commandes_factures_commandes_item") {
        message = this.$t("context-menu.actions-applicables-a-la-commande");
      }

      return message;
    },
    isDe() {
      return this.country === "de";
    },
    btnAssistanceAttr() {
      const attr = {};
      if (!this.isDe) {
        attr.baliseType = "a";
        attr.extLink = this.urlHotline;
      }
      return attr;
    },
    mailLde() {
      return config.mails.lde;
    },
  },
  methods: {
    customCopyToClipboard(text, title = null) {
      const el = document.createElement("textarea");
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.success({ title: title || this.$t("toast.element-copie-presse-papier") });
    },
    copyMailLde() {
      if (this.isDe) {
        this.customCopyToClipboard(
          this.mailLde, this.$tc("general.email-copiee", 0, { mail: this.mailLde }),
        );
      }
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

#liste_actions {
  >p {
    margin-bottom: var.$space-x-tiny;
    color: var.$gray-medium;
  }

  .actions {
    margin-bottom: var.$space-small;

    svg {
      height: var.$space-tiny;
      width: var.$space-tiny;
    }
  }

  .centered {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .buttons-container .button-status:not(:last-child) {
    margin-right: var.$space-tiny;
  }
}
</style>
