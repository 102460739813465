<template>
  <div id="bloc_lignes_catalogues">
    <section
      v-for="catalogue in catalogues"
      :id="catalogue.id"
      :key="catalogue.key"
    >
      <template v-if="catalogue.lignes.length">
        <h2 class="s4 bold">
          {{ catalogue.title }}
        </h2>
        <Timeline
          v-if="timeline"
          :object-infos="objectInfos"
          :catalogue="catalogue.key === 'autres' ? 'papier' : catalogue.key"
        />
        <p
          v-if="catalogue.depasse_dotation"
          class="alert-dotation text-medium"
        >
          {{ $t('liste.liste-depasse-la-dotation') }}
          ({{ $t("dotation.dotation-restante") }}{{ "\xa0" }}:
          {{ budget?.solde }}{{ "\xa0" }}{{ budgetType }})
        </p>
        <ProductTable
          :rows="catalogue.lignes"
          :is-commande="isCommande"
          :disable-editing="disableEditing"
          :no-deletion-possible="noDeletionPossible"
          :expired="expired"
          @sort="$emit('sort', { lignes: $event, catalogue: catalogue.key, })"
          @change-line="$emit('change-line', $event)"
          @delete-line="$emit('delete-line', $event)"
        />
      </template>
    </section>
  </div>
</template>

<script>
import Timeline from "@/components/timeline/Timeline.vue";
import ProductTable from "@/components/table/ProductTable.vue";

/**
 * Affiche les ressources numériques, les livres papier et les fournitures d'une liste, d'un devis ou d'une commande.
 */
export default {
  name: "BlocLignesCatalogues",
  components: {
    Timeline,
    ProductTable,
  },
  props: {
    /**
     * Informations sur la liste, le devis ou la commande.
     */
    objectInfos: {
      type: Object,
      required: true,
    },
    /**
     * Définit si le contenu de la page est liée aux listes/devis ou aux commandes.
     */
    isCommande: {
      type: Boolean,
      default: false,
    },
    /**
     * Bloque l'insertion de la quantité et la checkbox.
     */
    disableEditing: {
      type: Boolean,
      default: false,
    },
    /**
     * Bloque la suppression d'une ligne.
     */
    noDeletionPossible: {
      type: Boolean,
      default: false,
    },
    /**
     * Variante d'affichage des produits si le devis est expiré.
     */
    expired: {
      type: Boolean,
      default: false,
    },
    /**
     * Permet de regrouper les lignes selon les catalogues.
     */
    catalogues: {
      type: Array,
      required: true,
    },
    /**
     * Affichage de la timeline.
     */
    timeline: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    /**
     * Déclenché à la modification d'une ligne.
     * @param {Object} $event Ligne à modifier.
     */
    "change-line",
    /**
     * Déclenché à la suppression de la ligne du produit.
     * @param {Object} $event Ligne à supprimer.
     */
    "delete-line",
    /**
     * Déclenché au tri sur les colonnes.
     */
    "sort",
  ],
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

#bloc_lignes_catalogues {
  >section {
    margin-bottom: var.$space-medium;

    h2 {
      margin-bottom: var.$space-xx-tiny;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 70rem;
    }
  }

  #etiquettes {
    .image {
      min-height: var.$space-large;

      img {
        width: var.$space-large;
      }
    }
  }

  #ressources_numeriques {
    .product-table {
      tbody {
        .image {
          width: var.$space-x-large;
          min-height: var.$space-x-large;
        }

        img {
          width: var.$space-x-large;
        }
      }
    }
  }
}
</style>
