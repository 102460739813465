var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeline",class:{ complete: _vm.complete, }},[_vm._t("default",function(){return [_c('div',{staticClass:"zone-timeline-label flex-vcenter"},_vm._l((_vm.timeline),function(tl,index){return _c('div',{key:index,class:[
          {
            'bloc-timeline': !tl.line,
            'line-separator': tl.line,
          },
          tl.colorClass,
        ]},[(!tl.line)?_c('TimelineLabel',{attrs:{"label":tl[tl.status].label,"status":tl.status,"tooltip":{
            title: tl.showHelpText ? tl[tl.status].helpText : null,
            placement: 'bottom',
          },"counter-label":tl.counterLabel,"small":""}}):_vm._e()],1)}),0)]}),(!_vm.complete && _vm.hasHistos)?_c('ButtonClassic',{directives:[{name:"matomo-log-click",rawName:"v-matomo-log-click",value:(['devis_suivi_detaille', ]),expression:"['devis_suivi_detaille', ]"}],attrs:{"label":_vm.$t('timeline.suivi-detaille'),"variant":"ghost","color":"primary","size":"small"},on:{"click":function($event){return _vm.openModal()}}}):_vm._e(),_c('ModalHistorique',{attrs:{"is-open":_vm.modalOpen,"object-infos":_vm.objectInfos,"catalogue":_vm.catalogue}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }