<template>
  <Modal
    id="modal_pieces_jointes"
    @change="!$event && $emit('hide')"
  >
    <template #title>
      {{ $t('piece-jointe.pieces-jointes') }}
    </template>
    <template #subtitle>
      {{ $t('piece-jointe.lajout-de-pieces-jointes-est-facultatif') }}
      <span v-html="$t('piece-jointe.poids-maximum-x-mo', { mo: maxSizeFile/1000, })" />
    </template>
    <template #content>
      <ul>
        <li
          v-for="(objFile, index) in addingFiles"
          :key="`${objFile.type.value}_${objFile.file ? objFile.file.name : ''}_${index}`"
          class="attachment-container"
        >
          <SelectClassic
            v-if="typeOptions.length"
            v-model="objFile.type"
            class="select-types"
            :name="`select_type_${index}`"
            :options="typeOptions"
            status="required"
            :label="$t('piece-jointe.quel-type-de-piece-ajoutez-vous')"
            :internal-search="false"
          >
            <template #label-icon>
              <UilFileQuestion size="16" />
            </template>
          </SelectClassic>
          <div
            v-if="!objFile.file"
            class="input-file"
            :class="{ draged: draged, }"
            @drop="previewFile($event, index)"
            @dragleave="draged = false"
            @dragover="dragover($event)"
          >
            <!-- On laisse un v-show="false" pour utiliser visuellement le ButtonClassic -->
            <input
              v-show="false"
              :id="`input_file_${index}`"
              ref="file"
              type="file"
              :accept="accept.join(',')"
              @change="previewFile($event, index)"
            />
            <label :for="`input_file_${index}`">
              <ButtonClassic
                variant="solid"
                :label="draged ? $t('piece-jointe.lachez-le-document') : $t('piece-jointe.parcourir-vos-dossiers')"
                color="primary"
                icon="left"
                size="medium"
                @click="$refs.file[0].click();"
              >
                <template #left-icon>
                  <UilFileUpload v-if="draged" />
                  <UilFolderUpload v-else />
                </template>
              </ButtonClassic>
            </label>
          </div>
          <div
            v-else
            class="uploaded-file flex-vcenter"
          >
            <p class="text-regular">
              {{ objFile.file.name }}
            </p>
            <div class="right flex-vcenter text-medium">
              <Tag
                v-if="objFile.type.label"
                :tag-name="objFile.type.label"
              />
              <p class="size">
                {{ objFile.file.size | convertSizeFile }}
              </p>
              <ButtonClassic
                variant="special"
                color="primary"
                icon="left"
                size="small"
                @click="deleteFile(index)"
              >
                <template #left-icon>
                  <UilTrashAlt />
                </template>
              </ButtonClassic>
            </div>
          </div>
        </li>
      </ul>
    </template>
    <template #footer>
      <ButtonClassic
        class="add-layout"
        variant="ghost"
        :label="$t('piece-jointe.ajouter-une-piece-jointe')"
        color="primary"
        icon="left"
        :disabled="!lastHasFile || !lastHasSelectType || isLoading"
        @click="addFileLayout()"
      >
        <template #left-icon>
          <UilPlus />
        </template>
      </ButtonClassic>
      <ButtonClassic
        v-bind="submitButtonProps"
        variant="solid"
        icon="right"
        @click="validate()"
      >
        <template #right-icon>
          <UilFileUpload />
        </template>
      </ButtonClassic>
    </template>
  </Modal>
</template>

<script>
import {
  ButtonClassic,
  SelectClassic,
  Modal,
  Tag,
} from "@lde/core_lde_vue";

import Api from "@/modules/axios";
import { convertSizeFile, checkFile } from "@/modules/utils";

import {
  UilFileQuestion,
  UilPlus,
  UilFileUpload,
  UilFolderUpload,
  UilTrashAlt,
} from "@iconscout/vue-unicons";

/**
 * Modale affichée lors de la création d'une liste.
 */
export default {
  name: "ModalPiecesJointes",
  components: {
    Modal,
    ButtonClassic,
    SelectClassic,
    UilFileQuestion,
    Tag,
    UilPlus,
    UilFileUpload,
    UilFolderUpload,
    UilTrashAlt,
  },
  filters: {
    convertSizeFile(value) {
      return convertSizeFile(value);
    },
  },
  props: {
    /**
     * Si au moins une des lignes de devis/commandes présente est adoptant.
     */
    adoptant: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    /**
     * Déclenché à la fermeture de la modale.
     */
    "hide",
    /**
     * Déclenché à l'ajout de la liste.
     */
    "confirm",
  ],
  data() {
    return {
      draged: false,
      addingFiles: [],
      maxSizeFile: 3000, // en ko (= 3 Mo)
      isLoading: false,
      // MIME Types acceptés
      accept: [
        "image/jpeg", // jpeg et jpg
        "image/png", // png
        "application/vnd.ms-excel", // xls
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xslx
        "application/pdf", // pdf
        "text/csv", // csv
      ],
    };
  },
  computed: {
    typeOptions() {
      const options = [{
        label: this.$t("devis-commande.bon-de-commande"),
        value: "bon_commande",
      }];

      if (this.adoptant) {
        options.push({
          label: this.$t("devis-commande.justificatif-adoptant"),
          value: "justificatif_adoptant",
        });
      }
      return options;
    },
    lastHasFile() {
      if (this.addingFiles.length) {
        return typeof this.addingFiles[this.addingFiles.length - 1].file === "object";
      }
      return false;
    },
    lastHasSelectType() {
      return Object.keys(this.addingFiles[this.addingFiles.length - 1].type).length > 0;
    },
    submitButtonProps() {
      if (this.lastHasFile) {
        return {
          label: this.$t("action.valider-et-continuer"),
          color: "secondary",
          disabled: (this.lastHasFile && !this.lastHasSelectType) || this.isLoading,
        };
      }
      return {
        label: this.$t("action.continuer"),
        color: "primary",
      };
    },
  },
  mounted() {
    this.initValues();
  },
  methods: {
    /**
     * Ferme la modale.
     */
    hide() {
      this.$modal.hide("modal_pieces_jointes");
    },
    /**
     * Valide les pièces jointes s'il y en a.
     */
    validate() {
      // Si on n'a pas de pièce jointe
      if (!this.addingFiles[0].file) {
        this.hide();
      } else {
        const { id } = this.$route.params;
        const formdata = new FormData();

        this.addingFiles.forEach(({ file, type }, index) => {
          formdata.append(`fichier_${index}`, file);
          formdata.append(`type_${index}`, type.value);
        });
        formdata.append("cible", id);

        let type = null;
        switch (this.$route.name) {
          case "listes_devis_devis_item":
            type = "devis";
            break;
          case "commandes_factures_commandes_item":
            type = "commande";
            break;
          default:
            break;
        }
        formdata.append("modele_type", type);

        this.isLoading = true;
        Api()
          .post("/piece_jointe/", formdata)
          .then(() => {
            this.hide();
            this.$toast.success({
              title: this.$tc("piece-jointe.piece-jointe-bien-ete-ajoutee", this.addingFiles.length),
            });
            this.$emit("confirm");
            this.initValues();
          })
          .catch((res) => {
            this.$toast.error({ title: res.response.data });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    /**
     * Gestion de l'upload des fichiers.
     * @param {Object} event Contient les fichiers importés.
     * @param {Number} index Index du fichier parmi tous les fichiers.
     */
    previewFile(event, index) {
      event.preventDefault();
      this.draged = false;
      this.checkedImg = null;

      const files = event.target.files || event.dataTransfer.files;
      const conf = {
        accept: this.accept,
        acceptedStr: ["jpeg", "jpg", "png", "xls", "xslx", "pdf"],
        maxSizeFile: this.maxSizeFile,
      };
      checkFile(files, conf)
        .then((file) => {
          this.$set(this.addingFiles[index], "file", file);
          this.$toast.success({
            title: this.$t("piece-jointe.fichier-televerse"),
          });
        }).catch(({ message }) => {
          this.$toast.error({ title: message });
        });
    },
    /**
     * Gestion lorsqu'on drag l'image.
     * @param {Object} event Événement natif de JS.
     */
    dragover({ preventDefault }) {
      preventDefault();
      this.draged = true;
    },
    /**
     * Ajoute un layout pour rajouter des fichiers.
     */
    addFileLayout() {
      this.addingFiles.push({ type: {} });
      this.initValues();
    },
    /**
     * Réinitialise les valeurs de tous les fichiers.
     */
    initValues() {
      if (this.typeOptions.length === 1) {
        if (this.addingFiles.length) {
          this.addingFiles.forEach((file) => {
            file.type = this.typeOptions[0];
          });
        } else {
          this.addingFiles = [{ type: this.typeOptions[0] }];
        }
      } else {
        this.addingFiles = [{ type: {} }];
      }
    },
    /**
     * Supprime le fichier selon l'index.
     * @param {Number} index Index du fichier à supprimer.
     */
    deleteFile(index) {
      this.addingFiles.splice(index, 1);
      this.initValues();
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/modals/modal_pieces_jointes.scss";
</style>
