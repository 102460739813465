<template>
  <section class="product-table">
    <TableClassic
      :columns="columns"
      :rows="customRows"
      :checkboxes="!disableEditing"
      internal-sort
      @sort="$emit('sort', $event)"
    >
      <template #image="{ value, }">
        <img
          :src="defineImgUrl(value)"
          :alt="$t('general.alt-image-produit')"
          @error="$set(imgHasError, value.id, true)"
        />
      </template>
      <template #libelle="{ value, }">
        <h2>
          {{ defineProduct(value).libelle }}
        </h2>
        <p v-if="defineProduct(value)?.reference_interdacta">
          {{ defineProduct(value)?.reference_interdacta }}
        </p>
        <p v-else-if="defineProduct(value)?.ean">
          {{ defineProduct(value)?.ean }}
        </p>
        <p v-if="defineOffer(value) && defineOffer(value).duree_verbose">
          {{ defineOffer(value).duree_verbose }}
        </p>
        <p v-if="defineOffer(value) && !compatibleAdoptant && catalogue(value) === 'numerique'">
          {{ defineOffer(value).adoptant ? $t('offre.adoptant') : $t('offre.non-adoptant') }}
        </p>
        <ul
          v-if="defineOffer(value) && value.gratuites_calculees"
          class="product-lep"
        >
          <li
            v-for="lep in defineOffer(value).livresenplus"
            :key="lep.id"
          >
            <template v-if="lep.id && value.gratuites_calculees[lep.id]">
              {{
                $tc(
                  "produit.x-licence-gratuite",
                  value.gratuites_calculees[lep.id].split(" pour ")[0],
                  { licence: value.gratuites_calculees[lep.id].split(" pour ")[0], }
                )
              }}
              ({{ lep.article_gratuit_verbose }}
              {{
                (lep.duree % 12) === 0
                  ? $tc("general.x-an", lep.duree / 12, { an: lep.duree / 12, })
                  : $t("general.x-mois", { mois: lep.duree, })
              }})
              {{ $tc(
                "produit.pour-x-achetee",
                value.gratuites_calculees[lep.id].split(" pour ")[1],
                { nombre: value.gratuites_calculees[lep.id].split(" pour ")[1], }
              ) }}
            </template>
          </li>
        </ul>
      </template>
      <template #prix="{ value, }">
        <template v-if="parseFloat(value.remise)">
          <Badge
            variant="remise"
            :title="`${value.remise}&nbsp;%`"
          />
          <span
            class="prix-sans-remise"
            v-html="definePrixNonRemise(value).ttc !== definePrixNonRemise(value).ht
              ? $t('prix.ttc', { prix: moneyFilter(definePrixNonRemise(value).ttc), })
              : $t('prix.ht', { prix: moneyFilter(definePrixNonRemise(value).ht), })
            "
          />
        </template>

        <template v-if="definePrixRemise(value)">
          <strong
            v-if="definePrixRemise(value).ttc !== definePrixRemise(value).ht"
            class="all-taxes"
            v-html="$t('prix.ttc', { prix: moneyFilter(definePrixRemise(value).ttc), })"
          />
          <strong
            v-else
            class="duty-free"
            v-html="$t('prix.ht', { prix: moneyFilter(definePrixRemise(value).ht), })"
          />
        </template>
      </template>
      <template #quantite="{ value, }">
        <InputQuantity
          v-model="value.quantite"
          inline
          :disabled="disableEditing || !isOfferAvailable(value.offre)"
          hide-buttons
          :status="!disableEditing && catalogue(value) === 'numerique' && value.quantite < value.offre.quantite_min
            ? 'warning'
            : 'default'
          "
          :min="quantityMin"
          :max="quantityMax"
          @click.native.stop
          @change="changeQuantity(value, $event)"
        />
      </template>
      <template #sous-total="{ value, }">
        <strong
          v-if="value.total_ligne_ttc !== value.total_ligne_ht"
          class="all-taxes"
          v-html="$t('prix.ttc', { prix: moneyFilter(value.total_ligne_ttc), })"
        />
        <span
          class="duty-free"
          v-html="$t('prix.ht', { prix: moneyFilter(value.total_ligne_ht), })"
        />
      </template>
      <template #statut="{ value, }">
        <template v-if="isCommande ? value.order_label : defineStatusFormated(value)">
          <TimelineLabel
            :label="isCommande ? value.order_label : defineStatusFormated(value).label"
            :status="isCommande ? value.order_status : defineStatusFormated(value).statut"
            small
          />
          <!-- TODO: date livraison estimée toujours pas gérée depuis 2021 mais c'est dans les maquettes,
            peut-être qu'un jour ça sera remarqué et demandé… -->
          <p
            v-if="value.date_livraison_estimee"
            v-html="`${$t('produit.livraison-estimee')} ${value.date_livraison_estimee}`"
          />
        </template>
      </template>
      <template #actions="{ value, }">
        <ButtonClassic
          v-tooltip="{
            content: $t('action.mettre-poubelle'),
            delay: { show: 800, },
          }"
          variant="special"
          color="primary"
          icon="left"
          size="small"
          @click.native.stop="$emit('delete-line', value)"
        >
          <template #left-icon>
            <UilTrashAlt />
          </template>
        </ButtonClassic>
      </template>

      <template #additional-line="{ value, }">
        <div
          v-if="value.raison"
          class="minima"
        >
          <p class="text-medium">
            {{
              $tc(
                "ask-licence.nombre-licence-enseignant-gratuites-x",
                value.gratuites,
                { nombre: value.gratuites, }
              )
            }}
          </p>
          <div v-if="value.quantite > value.gratuites">
            <br /> <br />
            <p>
              {{ $t('ask-licence.text-raison-licence-supp') }}{{ "\xa0" }}:
            </p>
            <ul>
              <li>
                <ButtonToggle
                  v-model="value.raison"
                  name="effectif_reduit"
                  input-value="effectif_reduit"
                  label-left
                  :label="$t('ask-licence.raison-effectif')"
                />
              </li>
              <li>
                <ButtonToggle
                  v-model="value.raison"
                  name="plsr_enseignants"
                  input-value="plsr_enseignants"
                  label-left
                  :label="$t('ask-licence.raison-plsr-enseignants')"
                />
              </li>
              <li>
                <ButtonToggle
                  v-model="value.raison"
                  name="handicap"
                  input-value="handicap"
                  label-left
                  :label="$t('ask-licence.raison-handicap')"
                />
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          <p :class="{ 'label-warning': !value.raison_minima, }">
            {{ $t('produit.informations-justifier-commandes-sous-minima') }}{{ "\xa0" }}:
          </p>
          <ul>
            <li>
              <ButtonToggle
                name="effectif_reduit"
                input-value="effectif_reduit"
                :value="value.raison_minima"
                label-left
                :label="$t('produit.classe-effectif-inferieur-quantite-minimale')"
                @change="changeMinima(value, $event)"
              />
            </li>
            <li>
              <ButtonToggle
                name="situation_handicap"
                input-value="situation_handicap"
                :value="value.raison_minima"
                label-left
                :label="$t('produit.atteste-commander-minima-handicap')"
                @change="changeMinima(value, $event)"
              />
            </li>
            <li>
              <p
                v-if="!value.is_complement"
                class="text-small italic no-complement"
              >
                {{ $t("produit.aucune-commande-detectee") }}
                <UisExclamationTriangle size="16" />
              </p>
              <ButtonToggle
                name="complement"
                input-value="complement"
                :value="value.raison_minima"
                label-left
                :label="$t('produit.jatteste-commander-dessous-minima')"
                :disabled="!value.is_complement"
                @change="changeMinima(value, $event)"
              />
            </li>
          </ul>
        </div>
      </template>
    </TableClassic>
  </section>
</template>

<script>
import {
  InputQuantity,
  ButtonClassic,
  ButtonToggle,
  moneyFilter,
} from "@lde/core_lde_vue";

import TableClassic from "@/components/table/TableClassic.vue";
import TimelineLabel from "@/components/timeline/TimelineLabel.vue";
import Badge from "@/components/Badge.vue";

import { UilTrashAlt } from "@iconscout/vue-unicons";
import { UisExclamationTriangle } from "@iconscout/vue-unicons-solid";

import mixinDisponibilite from "@/mixins/mixinDisponibilite";

import { mapGetters } from "vuex";

/**
 * Affiche les produits dans un tableau spécifique.
 */
export default {
  name: "ProductTable",
  components: {
    InputQuantity,
    ButtonClassic,
    ButtonToggle,
    TableClassic,
    TimelineLabel,
    Badge,
    UilTrashAlt,
    UisExclamationTriangle,
  },
  mixins: [mixinDisponibilite],
  model: {
    prop: "ligne",
    event: "change",
  },
  props: {
    /**
     * Données à afficher dans le tableau.
     */
    rows: {
      type: Array,
      required: true,
    },
    /**
     * Définit si le contenu de la page est liée aux listes/devis ou aux commandes.
     */
    isCommande: {
      type: Boolean,
      required: true,
    },
    /**
     * Empêche la modification de la quantité et cache la checkbox.
     */
    disableEditing: {
      type: Boolean,
      default: false,
    },
    /**
     * Empêche la suppression d'une ligne.
     */
    noDeletionPossible: {
      type: Boolean,
      default: false,
    },
    /**
     * Variante d'affichage des lignes du tableau si le devis est expiré.
     */
    expired: {
      type: Boolean,
      default: false,
    },
    /**
     * Quantité minimale pour l'input de la ligne.
     */
    quantityMin: {
      type: Number,
      default: 1,
    },
    /**
     * Quantité maximale pour l'input de la ligne.
     */
    quantityMax: {
      type: Number,
      default: 9999,
    },
  },
  emits: [
    /**
     * Déclenché à la modification d'une ligne.
     * @param {Object} ligne Ligne à modifier.
     */
    "change-line",
    /**
     * Déclenché au clic sur le bouton de suppression.
     * @param {Object} ligne Ligne à modifier.
     */
    "delete-line",
    /**
     * Au choix de la raison du minima.
     */
    "select-minima",
  ],
  data() {
    return {
      imgHasError: {},
    };
  },
  computed: {
    ...mapGetters(["compatibleAdoptant"]),
    columns() {
      let columns = [
        {
          key: "image",
          name: this.$t("liste.image"),
          notSortable: true,
        },
        {
          key: "libelle",
          name: this.$t("liste.titre-et-ean"),
          sortKey: "offre.manuel_numerique.libelle",
        },
        {
          key: "prix",
          name: this.$t("prix.prix"),
          notSortable: true,
        },
        {
          key: "quantite",
          name: this.$t("general.quantite"),
        },
      ];

      if (!this.isCommande) {
        columns = [
          ...columns,
          {
            key: "sous-total",
            name: this.$t("general.sous-total"),
            sortKey: "total_ligne_ht",
          },
        ];
      }

      columns = [
        ...columns,
        {
          key: "statut",
          name: this.$t("general.statut"),
        },
      ];

      if (!this.noDeletionPossible) {
        columns = [
          ...columns,
          {
            key: "actions",
            notSortable: true,
          },
        ];
      }

      return columns;
    },
    noImgName() {
      const firstLine = this.rows[0];
      let type = "";

      if (firstLine.manuel_numerique) {
        type = "numerique";
      } else if (firstLine.article) {
        type = "papier";
      } else if (firstLine.fourniture) {
        type = "fourniture";
      } else {
        type = "image";
      }

      return type;
    },
    customRows() {
      return this.rows.map((row) => {
        row._additional = (
          !this.disableEditing && (
            row._force_additional || (
              this.catalogue(row) === "numerique"
              && row.quantite < row.offre.quantite_min
              && row.quantite > 0
            )
          )
        );

        row._variant = this.expired ? "dashed" : "";
        return row;
      });
    },
  },
  methods: {
    moneyFilter,
    /**
     * Définit le catalogue de l'offre.
     * @param {Object} ligne Infos de la lignes.
     * @returns {Object} Produit de la ligne.
     */
    catalogue(ligne) {
      if (this.defineOffer(ligne)?.manuel_numerique) {
        return "numerique";
      }
      if (this.defineOffer(ligne)?.fourniture) {
        return "fourniture";
      }
      if (this.defineOffer(ligne)?.article) {
        return "papier";
      }
      return "autre";
    },
    /**
     * Définit le produit selon son catalogue.
     * @param {Object} ligne Infos de la lignes.
     * @returns {Object} Produit de la ligne.
     */
    defineProduct(ligne) {
      if (ligne.etat_code !== undefined) {
        // Etiquette
        return ligne.article;
      }

      return ligne?.offre?.manuel_numerique
        || ligne?.offre?.fourniture
        || ligne?.offre?.article
        || ligne?.offre;
    },
    /**
     * Définit l'offre selon son catalogue.
     * @param {Object} ligne Infos de la ligne.
     * @returns {Object} Offre de la ligne.
     */
    defineOffer(ligne) {
      return ligne.offre || this.defineProduct(ligne).offre;
    },
    /**
     * Définit l'image d'une ligne.
     * @param {Object} ligne Infos de la lignes.
     * @returns {String} URL de l'image.
     */
    defineImgUrl(ligne) {
      if (ligne.etat_code !== undefined) {
        // Etiquette
        return ligne.article.url_couverture;
      }

      if (this.imgHasError[ligne.id] || this.catalogue(ligne) === "autre") {
        // eslint-disable-next-line import/no-dynamic-require, global-require
        return require(`@lde/core_lde_vue/dist/assets/media/missing_img/no_img_${this.noImgName}.svg`);
      }

      if (this.catalogue(ligne) === "fourniture") {
        return `https://www.interdacta.fr/photos-1/300x300/${this.defineProduct(ligne).reference_interdacta}.jpg`;
      }

      return this.defineProduct(ligne).url_couverture;
    },
    /**
     * Définit le prix non remisé dd'une ligne.
     * @param {Object} ligne Infos de la lignes.
     * @returns {Object} Prix TTC et HT de l'offre.
     */
    definePrixNonRemise(ligne) {
      return {
        ttc: ligne.liste ? ligne.offre.prix_editeur : ligne.prix_editeur_ttc,
        ht: ligne.liste ? ligne.offre.prix_ht : ligne.prix_editeur_ht,
      };
    },
    /**
     * Définit le prix remisé d'une ligne.
     * @param {Object} ligne Infos de la lignes.
     * @returns {Object|Boolean} Prix TTC et HT de l'offre ou false si aucun prix défini.
     */
    definePrixRemise(ligne) {
      // On passe par un computed pour avoir un nom plus compréhensible par rapport au reste.
      if (ligne.prix_unitaire_ttc == null && ligne.prix_unitaire_ht == null) {
        return false;
      }

      return {
        ttc: ligne.prix_unitaire_ttc,
        ht: ligne.prix_unitaire_ht,
      };
    },
    /**
     * Change la quantité d'une ligne.
     * @param {Object} ligne Infos de la ligne.
     * @param {Number} val Nouvelle quantité.
     */
    changeQuantity(ligne, val) {
      ligne.quantite = val;
      this.$emit("change-line", ligne);
    },
    /**
     * Définit le statut d'une ligne.
     * @param {Object} ligne Infos de la lignes.
     * @returns {Object} Couleur et label du statut.
     */
    defineStatusFormated(ligne) {
      const obj = {
        1: { label: this.$t("produit.disponible"), statut: "valid" },
        2: { label: this.$t("produit.a-paraitre"), statut: "warning" },
        3: { label: this.$t("produit.en-cours-de-reimpression"), statut: "warning" },
        4: { label: this.$t("produit.non-disponible-provisoirement"), statut: "warning" },
        5: { label: this.$t("produit.changement-de-distributeur"), statut: "warning" },
        6: { label: this.$t("produit.arret-definitif-de-commercialisation"), statut: "error" },
        7: { label: this.$t("produit.manque-sans-date"), statut: "error" },
        8: { label: this.$t("produit.a-reparaitre"), statut: "warning" },
        9: { label: this.$t("produit.non-disponible-a-long-terme"), statut: "error" },
        97: { label: this.$t("produit.disponible-jusqu-a-epuisement-des-stocks"), statut: "valid" },
        98: { label: this.$t("produit.retire-du-catalogue-editeur"), statut: "error" },
      };

      return ligne.offre?.code_disponibilite ? obj[ligne.offre.code_disponibilite] : {};
    },
    /**
     * Change la raison du minima d'une ligne.
     * @param {Object} ligne Infos de la ligne.
     * @param {String} val raison du minima.
     */
    changeMinima(ligne, val) {
      this.$set(ligne, "raison_minima", val);
      this.$emit("change-line", ligne);
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/table/product_table.scss";
</style>
