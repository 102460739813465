<template>
  <Modal
    v-if="isOpen"
    :id="`modal_historique_${catalogue}`"
  >
    <template #title>
      {{ $t("timeline.suivi-detaille") }}
    </template>
    <template #subtitle>
      {{ $t("timeline.cette-chronologie-evolue") }}
    </template>
    <template #content>
      <Timeline
        :object-infos="objectInfos"
        complete
      >
        <div
          v-for="tl, index in timeline"
          :key="index"
          class="bloc-timeline"
          :class="tl.colorClass"
        >
          <div
            v-if="tl.histo?.utilisateur"
            class="zone-avatar"
          >
            <div class="flex-vcenter">
              <Avatar
                :img-name="tl.histo.utilisateur.avatar_from_library"
                :ext-img="tl.histo.utilisateur.avatar"
              />
              <span class="name text-regular">{{ tl.histo.utilisateur.name }}</span>
            </div>
          </div>
          <div class="zone-label flex-vcenter">
            <TimelineLabel
              :label="tl[tl.status].label"
              :status="tl.status"
              :counter-label="tl.counterLabel"
            />
            <p
              v-if="tl.status !== 'awaiting' && tl.histo && tl.histo.date"
              class="action text-regular"
            >
              <span>{{ tl.dateLabel }}</span>{{ tl.histo.date | moment($t("global.format-date")) }}
            </p>
          </div>
          <p
            v-if="tl.showHelpText && tl[tl.status]"
            class="infos-supp text-regular"
          >
            {{ tl[tl.status].helpText }}
          </p>
        </div>
        <figure
          id="lock_icon"
          :class="{ finished: objectInfos.statut_affiche === 'livree', }"
        >
          <UilPadlock />
        </figure>
      </Timeline>
    </template>
  </Modal>
</template>

<script>
import { Modal, Avatar } from "@lde/core_lde_vue";

import TimelineLabel from "@/components/timeline/TimelineLabel.vue";

import { getWording, defineCounterLabel } from "@/modules/timeline_utils";
import Api from "@/modules/axios";

import { UilPadlock } from "@iconscout/vue-unicons";

import { mapGetters } from "vuex";

/**
 * Modale contenant l'avancée d'une commande.
 */
export default {
  name: "ModalHistorique",
  components: {
    Modal,
    Avatar,
    Timeline: () => import("@/components/timeline/Timeline.vue"), // Évite une boucle d'appel entre les 2 composants
    TimelineLabel,
    UilPadlock,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    /**
     * Informations sur la commande en cours.
     */
    objectInfos: {
      type: Object,
      required: true,
    },
    /**
     * Définit l'id de la modale selon le catalogue.
     */
    catalogue: {
      type: String,
      validator: (value) => ["numerique", "papier", "fourniture", "papier_fourniture"].includes(value),
      default: "numerique",
    },
  },
  data() {
    return {
      timeline: [],
    };
  },
  computed: {
    ...mapGetters(["hasPerm"]),
  },
  mounted() {
    this.fetchHistorique();
  },
  methods: {
    defineCounterLabel,
    /**
     * Récupère les informations de la timeline du processus de commande.
     */
    fetchHistorique() {
      let type = null;

      switch (this.$route.name) {
        case "listes_devis_listes_item":
          type = "liste";
          break;
        case "listes_devis_devis_item":
          type = "devis";
          break;
        case "commandes_factures_commandes_item":
          type = "commande";
          break;
        default:
          break;
      }

      Api().get(`/historique/?modele_cible=${this.objectInfos.id}&modele_type=${type}&page_size=100`)
        .then((res) => {
          const listeToCommande = this.objectInfos.liste && this.objectInfos.devis === null;
          const hasMarche = this.objectInfos?.organisme?.marches?.length > 0;
          const [wording, normalActions] = getWording(listeToCommande, hasMarche);

          const infos = [];

          // Étapes "valid" provenant des historiques
          res.data.results.forEach((histo) => {
            let status = "valid";
            if (histo.action === "expire") {
              status = "expire";
            } else if (histo.action === "demander_modification") {
              status = "warning";
            } else if (["refuser", "annuler_commande"].includes(histo.action)) {
              status = "error";
            } else if (![...normalActions, "modifier_devis", "liste_transferee"].includes(histo.action)) {
              // Au cas où l'historique n'est pas reconnu
              return;
            }

            infos.push({
              ...wording[histo.action],
              histo,
              status,
              showHelpText: true,
              counterLabel: defineCounterLabel(histo.action, this.objectInfos),
            });
          });

          const needEditing = this.objectInfos.modifiable && this.objectInfos.date_demande_modification;

          if (infos.at(-1).histo.action === "demander_modification" && needEditing) {
            // On copie l'avant-dernier élément donc on "spread" pour ne pas modifier les 2 éléments identiques en
            // même temps.
            infos.push({
              ...wording.modifier_devis,
              status: "awaiting",
              showHelpText: true,
            });
          }

          // Trouver le dernier historique dont l'action n'est pas "dynamique".
          const lastRealStep = [...infos].reverse().find(
            (step) => step.histo && normalActions.includes(step.histo.action),
          );

          const indexLastHisto = lastRealStep
            ? normalActions.findIndex((action) => action === lastRealStep.histo.action)
            : 0;

          // Étapes "awaiting"
          normalActions.slice(indexLastHisto + 1).forEach((action, index) => {
            infos.push({
              ...wording[action],
              status: "awaiting",
              showHelpText: index === 0
                && infos.at(-1).histo
                && ![
                  "liste_transferee", "demander_modification", "refuser", "actualiser_devis", "annuler_commande",
                ].includes(infos.at(-1).histo.action),
              counterLabel: defineCounterLabel(action, this.objectInfos),
            });
          });

          infos.forEach((currentAction, index) => {
            const nextAction = infos[index + 1];
            if (nextAction) {
              currentAction.colorClass = `${currentAction.status}-to-${nextAction.status}`;
            }
          });

          this.timeline = infos;
        });
    },
  },
};
</script>

<style lang="scss">
#modal_historique_commande {
  .modal-content { max-height: 65rem; }

  .modal-body {
    display: flex;
    justify-content: flex-start;
  }
}
</style>
