<template>
  <div
    class="button-status"
    :class="{ large, }"
  >
    <ButtonClassic
      v-if="['primary', 'secondary', 'positive', ].includes(variant)"
      variant="solid"
      :color="variant"
      :label="label"
      :disabled="disabled"
      icon="right"
      :size="large ? 'large' : 'medium'"
      @click="$emit('click')"
    >
      <template #right-icon>
        <!-- @slot Emplacement pour une icône. -->
        <component
          :is="icon"
          v-if="!$slots.icon"
        />
        <slot
          v-else
          name="icon"
        />
      </template>
    </ButtonClassic>
    <div
      v-else
      class="status flex-vcenter"
      :class="variant"
    >
      <strong
        class="label"
        :class="large ? 's6' : 'text-medium'"
      >
        {{ label }}
      </strong>
      <component
        :is="icon"
        v-if="!$slots.icon"
      />
      <!-- @slot Emplacement pour une icône. -->
      <slot
        v-else
        name="icon"
      />
    </div>
  </div>
</template>

<script>
import { ButtonClassic } from "@lde/core_lde_vue";

import { UilCheckCircle, UilSyncExclamation } from "@iconscout/vue-unicons";
import { UisCheckCircle, UisExclamationOctagon } from "@iconscout/vue-unicons-solid";

/**
 * Affiche une information qui définit le statut d'un élément.
 * Selon le statut, elle peut être un bouton ou une simple div.
 */
export default {
  name: "ButtonStatusApprobation",
  components: {
    ButtonClassic,
  },
  props: {
    /**
     * Variante du bouton.
     */
    variant: {
      type: String,
      validator: (value) => [
        "primary",
        "positive",
        "secondary",
        "warning",
        "white",
        "success",
        "error",
      ].includes(value),
      default: "primary",
    },
    /**
     * Label du bouton.
     */
    label: {
      type: String,
      default: "",
    },
    /**
     * Bouton en grande taille.
     */
    large: {
      type: Boolean,
      default: false,
    },
    /**
     * Désactive le bouton.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    /**
     * Déclenché au clic du bouton.
     */
    "click",
  ],
  computed: {
    icon() {
      let icon = null;
      switch (this.variant) {
        case "white":
          icon = UilCheckCircle;
          break;
        case "success":
          icon = UisCheckCircle;
          break;
        case "error":
          icon = UisExclamationOctagon;
          break;
        case "warning":
          icon = UilSyncExclamation;
          break;
        default:
          icon = UilCheckCircle;
      }
      return icon;
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/buttons/button_status_approbation.scss";
</style>
