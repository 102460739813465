import i18n from "@/modules/i18n";
import store from "@/store/index";
import typeMarcheTraduction from "@/modules/trad_utils";

export const getWording = (listeToCommande, hasMarche) => {
  let wording = {
    creer_liste: {
      valid: {
        label: i18n.t("timeline.liste"),
        helpText: i18n.t("timeline.cette-liste-creee"),
      },
      dateLabel: i18n.t("timeline.detail.creee-le"),
    },
    creer_devis: {
      awaiting: {
        label: i18n.t("devis.devis"),
        helpText: i18n.t("timeline.liste-doit-etre-transformee-devis"),
      },
      valid: {
        label: listeToCommande ? i18n.t("timeline.commande") : i18n.t("timeline.devis-cree"),
        helpText: listeToCommande
          ? i18n.t("timeline.cette-commande-enregistree-lde")
          : i18n.t("timeline.ce-devis-cree"),
      },
      dateLabel: i18n.t("timeline.detail.passe-devis-le"),
    },
    valider_etablissement: {
      awaiting: {
        label: i18n.t("timeline.validation-etablissement"),
        helpText: store.getters.hasPerm("can_transmettre_devis")
          ? i18n.t("timeline.ce-devis-attente-votre-validation")
          : i18n.t("timeline.ce-devis-doit-etre-valide-par-responsable-commandes"),
      },
      valid: {
        label: i18n.t("timeline.valide-par-etablissement"),
        helpText: store.getters.hasPerm("can_transmettre_devis")
          ? i18n.t("timeline.ce-devis-a-ete-valide")
          : i18n.t("timeline.ce-devis-a-ete-valide-par-responsable-commandes"),
      },
      dateLabel: i18n.t("timeline.detail.valide-par-etablissement-le"),
    },
  };

  if (hasMarche) {
    wording = {
      ...wording,
      soumettre_region: {
        awaiting: {
          label: typeMarcheTraduction().timelineSoumissionRegion,
          helpText: store.getters.hasPerm("can_transmettre_devis")
            ? typeMarcheTraduction().timelineCeDevisAttenteVotreSoumission
            : typeMarcheTraduction().timelineCeDevisAttenteSoumission,
        },
        valid: {
          label: typeMarcheTraduction().timelineSoumisRegion,
          helpText: store.getters.hasPerm("can_devis_to_cmd")
            ? i18n.t("timeline.devis-vous-a-ete-soumis")
            : typeMarcheTraduction().timelineCeDevisAEteSoumis,
        },
        dateLabel: i18n.t("timeline.detail.soumis-par-etablissement-le"),
      },
      approuver_region: {
        awaiting: {
          label: typeMarcheTraduction().timelineApprobationRegion,
          helpText: store.getters.hasPerm("can_devis_to_cmd")
            ? i18n.t("timeline.ce-devis-attente-votre-approbation")
            : typeMarcheTraduction().timelineCeDevisAttenteApprobation,
        },
        valid: {
          label: typeMarcheTraduction().timelineApprouveRegion,
          helpText: store.getters.hasPerm("can_devis_to_cmd")
            ? i18n.t("timeline.ce-devis-a-ete-approuve")
            : typeMarcheTraduction().timelineCeDevisAEteApprouveParRegion,
        },
        dateLabel: typeMarcheTraduction().timelineDetailApprouveParRegionLe,
      },
    };
  }

  wording = {
    ...wording,
    commander: {
      awaiting: {
        label: i18n.tc("commande.commande"),
        helpText: i18n.t("timeline.ce-devis-attente-commande"),
      },
      valid: {
        label: i18n.t("timeline.commande"),
        helpText: i18n.t("timeline.cette-commande-enregistree-lde"),
      },
      dateLabel: i18n.t("timeline.detail.commande-le"),
    },
    traiter: {
      awaiting: {
        label: i18n.t("timeline.traitement-editeur"),
        helpText: i18n.t("timeline.cette-commande-attente-traitement"),
      },
      valid: {
        label: i18n.t("timeline.traite-par-editeur"),
        helpText: i18n.t("timeline.cette-commande-en-cours-traitement"),
      },
    },
    mettre_disposition: {
      awaiting: {
        label: i18n.t("timeline.mise-a-disposition"),
        helpText: i18n.t("timeline.cette-commande-sera-deposee-environnement"),
      },
      valid: {
        label: i18n.t("timeline.mis-a-disposition"),
        helpText: i18n.t("timeline.cette-commande-a-ete-deposee-environnement"),
      },
    },
    demander_modification: {
      warning: {
        label: i18n.t("timeline.en-attente-de-modification"),
        helpText: i18n.t("timeline.devis-doit-etre-modifie"),
      },
      dateLabel: i18n.t("timeline.demande-modifier-le"),
    },
    modifier_devis: {
      awaiting: {
        label: i18n.t("timeline.modification"),
        helpText: i18n.t("timeline.devis-pas-encore-ete-modifie"),
      },
      valid: {
        label: i18n.t("timeline.modifie"),
        helpText: i18n.t("timeline.devis-a-ete-modifie"),
      },
      dateLabel: i18n.t("timeline.detail.modifie-le"),
    },
    refuser: {
      error: {
        label: i18n.t("timeline.refuse"),
        helpText: i18n.t("timeline.ce-devis-a-ete-refuse"),
      },
      dateLabel: i18n.t("timeline.detail.refuse-le"),
    },
    expire: {
      expire: {
        label: i18n.t("timeline.expire"),
        helpText: i18n.t("timeline.ce-devis-est-expire"),
      },
    },
    actualiser_devis: {
      valid: {
        label: i18n.t("timeline.renouvele"),
        helpText: i18n.t("timeline.ce-devis-a-ete-renouvele"),
      },
      dateLabel: i18n.t("timeline.actualise-le"),
    },
    annuler_commande: {
      error: {
        label: i18n.t("timeline.annulee"),
        helpText: i18n.t("timeline.cette-commande-a-ete-annulee"),
      },
      dateLabel: i18n.t("timeline.detail.annulee-le"),
    },
    liste_transferee: {
      valid: {
        label: i18n.t("timeline.transferee"),
        helpText: i18n.t("timeline.cette-liste-transferee"),
      },
      dateLabel: i18n.t("timeline.detail.transferee-le"),
    },
  };

  let normalActions = [
    "creer_liste",
    "creer_devis",
    "valider_etablissement",
  ];

  if (hasMarche) {
    normalActions = [
      ...normalActions,
      "soumettre_region",
      "approuver_region",
    ];
  }

  normalActions = [
    ...normalActions,
    "commander",
    "traiter",
    "mettre_disposition",
  ];

  return [wording, normalActions];
};

/**
 * Définit le label et l'état (couleur) pour le "counter" s'il y en a un.
 * @param {String} action Action de l'étape actuelle.
 */
export function defineCounterLabel(action, objectInfos) {
  if (action === "traiter") {
    return objectInfos.totalExpediees;
  }
  if (action === "mettre_disposition" && objectInfos.statut_affiche !== "attente_expedition") {
    return objectInfos.totalLivrees;
  }
  return null;
}
