<template>
  <div
    class="timeline"
    :class="{ complete, }"
  >
    <!-- @slot Emplacement pour les étapes de la timeline. -->
    <slot>
      <div class="zone-timeline-label flex-vcenter">
        <div
          v-for="tl, index in timeline"
          :key="index"
          :class="[
            {
              'bloc-timeline': !tl.line,
              'line-separator': tl.line,
            },
            tl.colorClass,
          ]"
        >
          <TimelineLabel
            v-if="!tl.line"
            :label="tl[tl.status].label"
            :status="tl.status"
            :tooltip="{
              title: tl.showHelpText ? tl[tl.status].helpText : null,
              placement: 'bottom',
            }"
            :counter-label="tl.counterLabel"
            small
          />
        </div>
      </div>
    </slot>
    <ButtonClassic
      v-if="!complete && hasHistos"
      v-matomo-log-click="['devis_suivi_detaille', ]"
      :label="$t('timeline.suivi-detaille')"
      variant="ghost"
      color="primary"
      size="small"
      @click="openModal()"
    />
    <ModalHistorique
      :is-open="modalOpen"
      :object-infos="objectInfos"
      :catalogue="catalogue"
    />
  </div>
</template>

<script>
import { ButtonClassic } from "@lde/core_lde_vue";

import TimelineLabel from "@/components/timeline/TimelineLabel.vue";
import ModalHistorique from "@/components/modals/ModalHistorique.vue";

import { getWording, defineCounterLabel } from "@/modules/timeline_utils";
import Api from "@/modules/axios";

/**
 * Affiche une liste de **TimelineLabel** horizontalement.
 */
export default {
  name: "Timeline",
  components: {
    ButtonClassic,
    TimelineLabel,
    ModalHistorique,
  },
  props: {
    /**
     * Informations de l'objet liste, devis ou commande.
     */
    objectInfos: {
      type: Object,
      required: true,
    },
    /**
     * Définit le catalogue pour l'historique détaillé.
     */
    catalogue: {
      type: String,
      validator: (value) => ["numerique", "papier", "fourniture", "papier_fourniture"].includes(value),
      default: "numerique",
    },
    /**
     * Affiche la timeline verticalement avec des détails supplémentaires.
     */
    complete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timeline: [],
      hasHistos: false,
      modalOpen: false,
    };
  },
  mounted() {
    this.fetchHistorique();
  },
  methods: {
    defineCounterLabel,
    /**
     * Ouvre la modale d'historique.
     * On doit passer par des props et des v-if pour ce cas à cause de l'import multiple de Timeline.
     */
    openModal() {
      this.modalOpen = true;
      // Timeout pour laisser le temps de créer l'élément dans le DOM
      setTimeout(() => {
        this.$modal.show(`modal_historique_${this.catalogue}`);
      }, 100);
    },
    /**
     * Récupère les informations de la timeline du processus de commande.
     */
    fetchHistorique() {
      const listeToCommande = this.objectInfos.liste && this.objectInfos.devis === null;
      const hasMarche = this.objectInfos?.organisme?.marches?.length > 0;
      const [wording, normalActions] = getWording(listeToCommande, hasMarche);

      let type = null;
      switch (this.$route.name) {
        case "listes_devis_listes_item":
          type = "liste";
          break;
        case "listes_devis_devis_item":
          type = "devis";
          break;
        case "commandes_factures_commandes_item":
          type = "commande";
          break;
        default:
          break;
      }

      // On définit les étapes de la timeline selon le marché et la page
      let steps = [];

      if (type !== "commande" || this.complete) {
        steps = [
          { statut: "attente_selection", action: "creer_liste" },
          { statut: "attente_validation", action: "creer_devis" },
        ];

        if (hasMarche) {
          steps = [
            ...steps,
            { statut: "attente_soumission", action: "valider_etablissement" },
            { statut: "attente_approbation", action: "soumettre_region" },
            { statut: "attente_commande", action: "approuver_region" },
          ];
        } else {
          steps = [
            ...steps,
            { statut: "attente_commande", action: "valider_etablissement" },
          ];
        }

        if (!this.complete) {
          steps = [
            ...steps,
            { statut: "attente_expedition", action: "commander" },
          ];
        }
      }

      if (type === "commande" || this.complete) {
        steps = [
          ...steps,
          { statut: "attente_expedition", action: "commander" },
          { statut: "attente_livraison", action: "traiter" },
          { statut: "livree", action: "mettre_disposition" },
        ];
      }

      const infos = [];
      let indexActualStep = 0;
      let promise = Promise.resolve();

      // Vérifie si l'on a au moins un historique
      const fetchHistos = Api().get(
        `/historique/?modele_cible=${this.objectInfos.id}&modele_type=${type}&page_size=100`,
      ).then((res) => {
        this.hasHistos = res.data.count > 0;
        return res;
      });

      // Trouver la dernière étape dont le statut n'est pas "dynamique".
      if (["attente_modification", "refus", "expire", "annulee"].includes(this.objectInfos.statut_affiche)) {
        promise = fetchHistos.then((res) => {
          const histos = res.data.results;
          const lastRealStep = [...histos].reverse().find((histo) => normalActions.includes(histo.action));
          indexActualStep = steps.findIndex((step) => step.action === lastRealStep.action);
        });
      } else {
        indexActualStep = steps.findIndex((step) => step.statut === this.objectInfos.statut_affiche);
      }

      // Étapes "valid"
      promise.then(() => {
        steps.slice(0, indexActualStep + 1).forEach((step) => {
          infos.push({
            ...wording[step.action],
            status: "valid",
            action: step.action,
            showHelpText: true,
            counterLabel: defineCounterLabel(step.action, this.objectInfos),
          });
        });

        if (this.objectInfos.statut_affiche === "expire") {
          infos.push({
            ...wording.expire,
            status: "expire",
            showHelpText: true,
          });
        } else if (this.objectInfos.statut_affiche === "attente_modification") {
          infos.push({
            ...wording.demander_modification,
            status: "warning",
            showHelpText: true,
          });

          infos.push({
            ...wording.modifier_devis,
            status: "awaiting",
            showHelpText: true,
          });
        } else if (this.objectInfos.statut_affiche === "refus") {
          infos.push({
            ...wording.refuser,
            status: "error",
            showHelpText: true,
          });
        } else if (this.objectInfos.statut_affiche === "annulee") {
          infos.push({
            ...wording.annuler_commande,
            status: "error",
            showHelpText: true,
          });
        }

        // Étapes "awaiting"
        steps.slice(indexActualStep + 1).forEach((step, index) => {
          infos.push({
            ...wording[step.action],
            status: "awaiting",
            action: step.action,
            showHelpText: index === 0
              && !["attente_modification", "refus", "annulee"].includes(this.objectInfos.statut_affiche),
            counterLabel: defineCounterLabel(step.action, this.objectInfos),
          });
        });

        const res = [];
        infos.forEach((info, index) => {
          res.push(info);
          if (index !== infos.length - 1) {
            res.push({ line: true });
          }
        });

        res.forEach((currentAction, index) => {
          const prev = res[index - 1];
          const next = res[index + 1];

          if (currentAction.line) {
            currentAction.colorClass = `${prev.status}-to-${next.status}`;
          } else if (this.objectInfos.statut_affiche === "expire" && index > indexActualStep + 3) {
            // On cible uniquement les éléments qui suivent le TimelineLabel "expiré"
            // 3 = ligne précédant l'état "expiré" + état "expiré" + ligne suivant l'état "expiré"
            currentAction.colorClass = "expired";
          }
        });

        this.timeline = res;
      });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/timeline/timeline.scss";
</style>
