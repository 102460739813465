<template>
  <div id="pieces_jointes">
    <h2 class="bold s5">
      {{ $t('piece-jointe.pieces-jointes') }}
    </h2>
    <ul v-if="attachments.length">
      <li
        v-for="attachment in attachments"
        :key="attachment.id"
        class="flex-vcenter button attachment"
      >
        <a
          class="flex-vcenter"
          :href="attachment.url"
          target="_blank"
          rel="noreferrer noopener"
        >
          <p class="text-regular name">
            {{ attachment.nom }}
          </p>
          <div class="flex-vcenter text-medium right">
            <Tag
              v-if="attachment.type && attachment.type_str"
              :tag-name="attachment.type_str"
            />
            <p
              v-if="attachment.poids"
              class="size"
            >
              {{ convertSizeFile(attachment.poids) }}
            </p>
          </div>
        </a>
        <ButtonClassic
          class="delete-file"
          variant="special"
          color="primary"
          icon="left"
          size="small"
          @click="$emit('delete-attachment', attachment)"
        >
          <template #left-icon>
            <UilTrashAlt />
          </template>
        </ButtonClassic>
      </li>
    </ul>
    <p
      v-else
      class="text-medium"
    >
      {{ $t("piece-jointe.aucune-piece-jointe-ajoutee-pour-le-moment") }}
    </p>
    <footer class="flex-vcenter">
      <ButtonClassic
        class="add-attachment"
        variant="special"
        label="Ajouter une pièce jointe"
        color="primary"
        icon="right"
        @click="$modal.show('modal_pieces_jointes')"
      >
        <template #right-icon>
          <UilPaperclip />
        </template>
      </ButtonClassic>
    </footer>
  </div>
</template>

<script>
import { ButtonClassic, Tag } from "@lde/core_lde_vue";

import { UilPaperclip, UilTrashAlt } from "@iconscout/vue-unicons";

import { convertSizeFile } from "@/modules/utils";

/**
 * Affiche les pièces jointes d'un devis/commande.
 */
export default {
  name: "PiecesJointes",
  components: {
    ButtonClassic,
    Tag,
    UilPaperclip,
    UilTrashAlt,
  },
  props: {
    /**
     * Liste des pièces jointes.
     */
    attachments: {
      type: Array,
      default: () => ([]),
    },
  },
  emits: [
    /**
     * Déclenché au clic sur le bouton de poubelle.
     * @param attachment Pièce jointe ajoutée.
     */
    "delete-attachment",
  ],
  methods: {
    convertSizeFile,
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/listes_devis_commandes/pieces_jointes.scss";
</style>
